
import {defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {ErrorMessage, Field} from "vee-validate";
import {useI18n} from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "account-buy",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const getCurses = ref([]);
    const type = ref();
    const submitButton = ref<HTMLElement | null>(null);

    const chooseCourse = (id, paymentCost) => {
      store.dispatch(Actions.SAVE_DATA_SUB_OBJ, {
        type: type.value,
        id,
        paymentCost,
      });
    };

    const buyCourse = () => {
      let courseObj = store.getters.getDataSubStep2;
      if (courseObj) {
        // Activate indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }
      // Dummy delay
      setTimeout(() => {
    
        const data = {
          course_id: courseObj.id,
        }

        store
          .dispatch(Actions.COURSE_BUY_BY_USER, data)
          .then(() => {
            window.location.href = store.getters.getPaymentUrl;
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: store.getters.getSubAuthError,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("good"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

      }, 500);
    };

    onMounted(() => {
      store.dispatch(Actions.CLEAR_DATA_SUB)
      setTimeout(() => {
        store
          .dispatch(Actions.CURSES_GET_ALL)
          .then(() => {
            const data = store.getters.getAllCurses;
            getCurses.value = data.map((item) => {
              return {
                isAvailable: item.available,
                _id: item._id,
                course_name: item.course_name ? item.course_name : "-",
                course_description: item.course_description
                    ? item.course_description
                    : "-",
                course_icon: item.course_icon ? item.course_icon : "-",
                __v: item.__v ? item.__v : 0,
                payment_cost: item.payment_cost ? item.payment_cost : "-",
              };
            });
          });
      }, 250);
    });


    return {
      t,
      buyCourse,
      chooseCourse,
      type,
      getCurses,
      submitButton,
    };
  },
});
